<template>
  <div class="social" v-if="artist && artist.links">
    <div>
      <h3>Follow {{ artist.name }}</h3>
    </div>
    <div class="links">
      <a v-if="artist.links.web" class="link" :href="artist.links.web"
        ><i class="fas fa-link"></i
      ></a>
      <a
        v-if="artist.links.facebook"
        :href="'https://facebook.com/' + artist.links.facebook"
        class="link"
        ><i class="fab fa-facebook-f"></i
      ></a>
      <a
        v-if="artist.links.instagram"
        :href="'https://instagram.com/' + artist.links.instagram"
        class="link"
        ><i class="fab fa-instagram"></i
      ></a>
      <a
        v-if="artist.links.twitter"
        :href="'https://twitter.com/' + artist.links.twitter"
        class="link"
        ><i class="fab fa-twitter"></i
      ></a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    artist: Object,
  },
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";
.social {
  border-top: 2px solid $gray6;
  border-bottom: 2px solid $gray6;
  padding: 25px 0;
  margin: 50px 0 20px;
  display: flex;
  h3 {
    padding-top: 7px;
    margin-bottom: 0;
  }
  .links {
    display: flex;
    justify-content: right;
    flex: 1;
    .link {
      color: $gray1;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $gray6;
      width: 40px;
      height: 40px;
      border-radius: $radius;
      margin-left: 10px;
    }
  }
}
</style>
