var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.artist && _vm.artist.links
    ? _c("div", { staticClass: "social" }, [
        _c("div", [_c("h3", [_vm._v("Follow " + _vm._s(_vm.artist.name))])]),
        _c("div", { staticClass: "links" }, [
          _vm.artist.links.web
            ? _c(
                "a",
                { staticClass: "link", attrs: { href: _vm.artist.links.web } },
                [_c("i", { staticClass: "fas fa-link" })]
              )
            : _vm._e(),
          _vm.artist.links.facebook
            ? _c(
                "a",
                {
                  staticClass: "link",
                  attrs: {
                    href: "https://facebook.com/" + _vm.artist.links.facebook,
                  },
                },
                [_c("i", { staticClass: "fab fa-facebook-f" })]
              )
            : _vm._e(),
          _vm.artist.links.instagram
            ? _c(
                "a",
                {
                  staticClass: "link",
                  attrs: {
                    href: "https://instagram.com/" + _vm.artist.links.instagram,
                  },
                },
                [_c("i", { staticClass: "fab fa-instagram" })]
              )
            : _vm._e(),
          _vm.artist.links.twitter
            ? _c(
                "a",
                {
                  staticClass: "link",
                  attrs: {
                    href: "https://twitter.com/" + _vm.artist.links.twitter,
                  },
                },
                [_c("i", { staticClass: "fab fa-twitter" })]
              )
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }