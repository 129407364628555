<template>
  <div>
    <BuyTickets :visible="true" @canceled="canceled" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";

import BuyTickets from "@/components/buy-tickets";

export default {
  props: ["id"],
  components: {
    BuyTickets,
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      event: "event",
      eventDates: "eventDates",
      venue: "venue",
    }),
    salesStatus: function () {
      let status = "Purchase Tickets";
      let futureDates = 0;
      let soldoutDates = 0;
      for (let x = 0; x < this.eventDates.length; x++) {
        if (
          this.eventDates[x].status == "soldout" &&
          this.eventDates[x].start.toDate() > Date.now()
        )
          soldoutDates++;
        if (this.eventDates[x].start.toDate() > Date.now()) futureDates++;
      }
      console.log("future dates", futureDates, Date.now());
      if (soldoutDates >= futureDates) status = "Sold Out";
      if (futureDates == 0) status = "Sales Closed";
      return status;
    },
  },
  async created() {
    console.log("ID ", this.id);
    if (!this.event || this.event.id != this.id)
      await store.dispatch("fetchEvent", this.id);
    if (this.event.venue) await store.dispatch("fetchVenue", this.event.venue);
    await store.dispatch("fetchEventDates");
    await store.dispatch("fetchPriceTiers");
  },
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";
.maincont {
  max-width: 878px;
}
.imagewrap {
  padding: 0 0 0 50px;
}
.event-image {
  border-radius: $radius;
}
.tix-button {
  float: right;
  margin-top: 25px;
}
.indent {
  margin: 0 75px;
}
.venue-map {
  border-radius: 6px;
  background-color: #f2f2f2;
  display: inline-block;
  width: 300px;
  float: right;
}
.map-legend {
  border-radius: 3px;
  background-color: white;
  border: 3px solid #f2f2f2;
  border-top: none;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 1rem 1.5rem;
}
.map-legend h3 {
  font-size: 1.2em;
  margin: 0;
}
.map-legend p {
  font-size: 0.8em;
  margin: 0px;
  line-height: 1.5em;
}
.vue-map-container .vue-map {
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
}
.timeline {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8em;
  margin-bottom: 1em;
}
.act {
  font-weight: bold;
  margin-top: 1em;
}
.calendar {
  height: 100px;
  width: 100px;
  border-radius: $radius;
  display: inline-block;
  position: relative;
  bottom: 40px;
  background: white;
}
.cal-stripe {
  background-color: $gray1;
  height: 15px;
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
}
.pastevent {
  opacity: 0.6;
}
.performances {
  clear: both;
  margin-bottom: 50px;
}
#datetable td {
  padding: 0 20px;
}
.soldout {
  color: #531753;
  font-weight: bold;
}
.limited {
  color: $active-green;
  font-weight: bold;
}
// mobile
@media screen and (max-width: 768px) {
  .body-wrapper {
    margin: 25px 0;
  }
  .event-image {
    border-radius: 0;
    margin: 0;
  }
  .calendar {
    margin-left: 15px;
  }
  .body-wrapper {
    margin: 0;
  }
  .indent {
    margin: 0 15px;
  }
  .venue-map {
    float: none;
    margin: 20px 30px;
  }
}
</style>
