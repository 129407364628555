<template>
  <div>
    <div>
      <div class="columns is-gapless">
        <div class="column is-one-fifth is-hidden-mobile"></div>
        <div v-if="festival" class="column maincont">
          <div class="imagewrap">
            <img :src="festival.image" class="event-image" />
          </div>
          <div class="calendar">
            <div class="cal-stripe"></div>
            <EventDateBadge
              v-if="festival && festival.start"
              :date="festival.start.toDate()"
            />
          </div>
          <div v-if="false" class="tix-button">
            <button class="button is-primary" @click="tickets()">
              Purchase Tickets
            </button>
          </div>
          <div class="description indent">
            <div><EventDateline :event="festival" /></div>
            <h1>{{ festival.title }}</h1>
            <div class="venue-map" v-if="venue">
              <gmap-map
                :center="venue.location"
                :zoom="17"
                mapTypeControl="false"
                style="width: 300px; height: 200px"
                :options="options"
              >
                <gmap-marker
                  :position="venue.location"
                  :clickable="true"
                  :draggable="true"
                  @click="center = venue.location"
                ></gmap-marker>
              </gmap-map>
              <div class="map-legend">
                <h3>{{ venue.name }}</h3>
                <p>{{ venue.fullAddress }}</p>
              </div>
            </div>
            <p class="act" v-if="festivalProfile">
              <img
                v-if="festivalProfile && festivalProfile.image"
                :src="festivalProfile.image"
                alt="festivalProfile.name"
              />
              <span v-if="!festivalProfile || !festivalProfile.urlslug">{{
                festivalProfile.name
              }}</span>
              <a
                v-if="festivalProfile && festivalProfile.urlslug"
                :href="'/festival/' + festivalProfile.urlslug"
                >{{ festivalProfile.name }}</a
              >
            </p>
            <div v-html="festival.description"></div>
            <h3>Multi-Show Passes</h3>
            <div
              v-for="pass in festival.passes"
              :key="pass.id"
              class="passclass"
            >
              <h4>{{ pass.name }}</h4>
              <div class="columns">
                <div class="column">{{ pass.description }}</div>
                <div class="column is-one-quarter" v-if="!pass.status || pass.status != 'soldout'">
                  <strong>${{ pass.price }} per pass</strong>
                </div>
                <div class="column is-one-quarter" v-if="pass.status && pass.status == 'soldout'">
                  <strong class="soldout">${{ pass.price }} per pass</strong> SOLD OUT
                </div>
              </div>
            </div>
            <div class="buttons is-right">
              <button
                class="button is-primary is-pulled-right"
                @click="passes()"
              >
                purchase passes
              </button>
            </div>
          </div>
          <div
            class="performances indent"
            v-if="!festival.eventType || festival.eventType != 'videoOnDemand'"
          >
            <FollowArtist v-if="false" :artist="festivalProfile"></FollowArtist>
          </div>
          <div class="indent">
            <h3>Festival Shows</h3>
          </div>
          <div class="event indent" v-for="event in events" :key="event.id">
            <div class="columns">
              <div class="column">
                <div class="eventimagerapper">
                  <div class="imageholder">
                    <img :src="event.image" alt="" class="eventimage" />
                  </div>
                </div>
                <div class="eventTitle">
                  <router-link
                    :to="{ name: 'event', params: { id: event.id } }"
                    >{{ event.title }}</router-link
                  >
                </div>
                <div>{{ event.act }}</div>
                <div class="showblurb" v-html="event.description"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-one-fifth is-hidden-mobile"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//import store from "@/store";
import EventDateBadge from "@/components/event-date-badge";
import EventDateline from "@/components/event-dateline";
import FollowArtist from "@/components/follow-artist";

export default {
  name: "festival",
  props: ["festivalslug"],
  components: {
    EventDateBadge,
    EventDateline,
    FollowArtist,
  },
  data() {
    return {
      options: {
        disableDefaultUI: true,
        styles: [
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
        mapId: "a7f1aa6c21b72aae",
      },
    };
  },
  computed: {
    ...mapGetters({
      events: "events",
      festival: "festival",
      festivalProfile: "festivalProfile",
      venue: "venue",
    }),
  },
  methods: {
    passes() {
      console.log("herde");
      // if (this.event.chargeSalesTax)
      //   store.dispatch("setTaxRate", this.event.salesTaxRate);
      this.$emit("sellPasses");
      this.$analytics.logEvent("pass_purchase_started", {
        source: "Primary CTA",
        festivalId: this.festival.id,
        festivalTitle: this.festival.title,
      });
    },
  },
  async created() {
    await this.$store.dispatch("fetchFestivalBySlug", this.festivalslug);
    this.$store.dispatch("fetchFestivalProfile", this.festival.festivalId);
    if (this.festival.venue)
      await this.$store.dispatch("fetchVenue", this.festival.venue);
    this.$store.dispatch("fetchFestivalEvents", this.festival.id);
  },
};
</script>

<style>
.datelist .cal-body {
  border: none;
}
.event {
  clear: both;
  margin-bottom: 10px;
}
.passclass {
  margin-bottom: 30px;
}
.showblurb {
  max-height: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.soldout {
  text-decoration: line-through;
}
</style>
