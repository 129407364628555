var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "body-wrapper" }, [
    _c("div", { staticClass: "body-content" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _vm.artist ? _c("h1", [_vm._v(_vm._s(_vm.artist.name))]) : _vm._e(),
          _vm._l(_vm.events, function (event) {
            return _c("div", { key: event.id, staticClass: "event" }, [
              _c("div", { staticClass: "columns" }, [
                _c(
                  "div",
                  { staticClass: "column is-narrow is-hidden-mobile datelist" },
                  [
                    event.start
                      ? _c("EventDateBadge", {
                          attrs: { date: event.start.toDate() },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "column" },
                  [
                    _c("div", { staticClass: "eventimagerapper" }, [
                      _c("div", { staticClass: "imageholder" }, [
                        _c("img", {
                          staticClass: "eventimage",
                          attrs: { src: event.image, alt: "" },
                        }),
                      ]),
                    ]),
                    _c("EventDateline", { attrs: { event: event } }),
                    _c(
                      "div",
                      { staticClass: "eventTitle" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: "event", params: { id: event.id } },
                            },
                          },
                          [_vm._v(_vm._s(event.title))]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "is-hidden-tablet" }, [
                      _vm._v(_vm._s(event.venueName)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "column is-narrow is-hidden-mobile" },
                  [_vm._v(_vm._s(event.venueName))]
                ),
              ]),
            ])
          }),
          _c("FollowArtist", { attrs: { artist: _vm.artist } }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }