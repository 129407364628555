<template>
    <div class="event" v-if="!event.passExempt">
        <div class="columns">
            <div class="column">
                <div class="eventimagerapper">
                <div class="imageholder">
                    <img :src="event.image" alt="" class="eventimage" />
                </div>
                </div>
                <div class="eventTitle">
                <router-link
                    :to="{ name: 'event', params: { id: event.id } }"
                    >{{ event.title }}</router-link
                >
                </div>
                <div>{{ event.act }}</div>
                <div class="showblurb" v-html="event.description"></div>
            </div>
        </div>
        <div v-if="use"><strong>You are seeing {{ event.title }} on {{ displayDate(use.date, use.time) }}</strong></div>
        <DateSlider
            v-if="!makingSelection && !use"
            :dates="event.dates"
            :visible="!makingSelection"
            @setDate="setPassDate"
        />
        <div v-if="!use" class="buttons is-right"><button class="button is-primary" :class="{ 'is-loading': makingSelection }" :disabled="!dateSelected" @click="makeSelection" >Select Performance</button></div>
    </div>
</template>

<script>
import moment from "moment";
import store from "@/store";
import { mapGetters } from "vuex";
import DateSlider from "@/components/date-slider-new";


export default {
  props: {
    event: Object,
    use: Object,
    passes: Array,
  },
  components: {
    DateSlider
  },
  data: function () {
    return {
        dateSelected: false,
        makingSelection: false,
        selectedDate: null
    };
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      profile: "profile",
      lineItems: "lineItems",
    })
  },
  methods: {
    setPassDate(date) {
      // console.log("blech", date);
      this.selectedDate = date;
      this.dateSelected = true;
    },
    displayDate: function (date, time) {
      // console.log("dater",date,time);
        const startTime = moment(date + " " + time);
        const displayTime =
          startTime.minutes() == 0
            ? startTime.format("h A")
            : startTime.format("h:mm A");
        return moment(startTime).format("dddd, MMMM Do") + " at " + displayTime;
    },
    async makeSelection() {
        this.makingSelection = true;
        const lineItem = {
            eventId: this.event.id,
            eventTitle: this.event.title,
            dateId: this.selectedDate.id,
            date: this.selectedDate.startDate,
            time: this.selectedDate.startTime,
            tierId: this.event.priceTiers[0].id,
            description: this.event.priceTiers[0].name,
            passDiscout: this.event.priceTiers[0].price,
            passId: this.passes[0].id,
            passUse: 1,
            quantity: 1,
            price: 0,
            total: 0,
            artistId: this.event.actId,
            artistName: this.event.act
          };
        console.log("line item", lineItem);
        store.dispatch("addToCart", lineItem);
        // store.dispatch("applyPass", {
        //           passId: this.passes[0].id,
        //           passname: this.passes[0].passname,
        //           uses: 1,
        //           discount: this.event.priceTiers[0].price,
        //         });
        const header = {
            eventId: this.event.id,
            lastName: this.profile.lastName,
            firstName: this.profile.firstName,
            email: this.profile.email,
            subtotal: 0,
            total: 0,
            // optin: this.optin,
            passDiscountTotal: this.event.priceTiers[0].price, 
        };
      console.log("header", header, this.lineItems);
      await store.dispatch("placeOrder", header);
      store.dispatch("clearCart");
    }
   }
}
</script>

<style>
.event {
    margin-bottom: 50px;
}
</style>