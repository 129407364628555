<template>
  <div class="body-wrapper">
    <div class="body-content">
      <div class="container">
        <h1 v-if="artist">{{ artist.name }}</h1>
        <div class="event"  v-for="event in events" :key="event.id"> <div class="columns">
          <div class="column is-narrow is-hidden-mobile datelist"><EventDateBadge :date="event.start.toDate()" v-if="event.start" /></div>
          <div class="column">
            <div class="eventimagerapper">
                <div class="imageholder">
                  <img :src="event.image" alt="" class="eventimage">
                </div>
              </div>
              <EventDateline :event="event" />
              <div class="eventTitle">
                <router-link
                  :to="{ name: 'event', params: { id: event.id } }"
                  >{{ event.title }}</router-link
                >
              </div>
              <div class="is-hidden-tablet">{{ event.venueName }}</div>
          </div>
          <div class="column is-narrow is-hidden-mobile">{{ event.venueName }}</div>
        </div></div>
        <FollowArtist :artist="artist"></FollowArtist>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//import store from "@/store";
import EventDateBadge from "@/components/event-date-badge";
import EventDateline from "@/components/event-dateline";
import FollowArtist from "@/components/follow-artist"

export default {
  name: "events",
  props: ["artistslug"],
  components: {
    EventDateBadge,
    EventDateline,
    FollowArtist
  },
  computed: {
    ...mapGetters({
      events: "events",
      artist: "artist",
    }),
  },
  methods: {},
  async created() {
    await this.$store.dispatch("fetchArtistBySlug", this.artistslug);
    this.$store.dispatch("fetchArtistEvents", this.artist.id);
  },
};
</script>

<style>
.datelist .cal-body {
  border: none;
}
.event {
  clear: both;
  margin-bottom: 10px;
}
</style>
