var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "body-wrapper" }, [
      _c("div", { staticClass: "columns is-gapless" }, [
        _c("div", { staticClass: "column is-one-fifth is-hidden-mobile" }),
        _vm.pass
          ? _c(
              "div",
              { staticClass: "column" },
              [
                _c("h1", [_vm._v("My " + _vm._s(_vm.pass.passname) + " Pass")]),
                _c("h2", [_vm._v(_vm._s(_vm.pass.festivalName))]),
                _c("p", [_vm._v(_vm._s(_vm.pass.passDescription))]),
                _c("h3", [_vm._v("Festival Shows")]),
                _vm._l(_vm.events, function (event) {
                  return _c("PassSelector", {
                    key: event.id,
                    attrs: {
                      passes: [_vm.pass],
                      event: event,
                      use: _vm.getUse(event.id),
                    },
                  })
                }),
              ],
              2
            )
          : _vm._e(),
        _c("div", { staticClass: "column is-one-fifth is-hidden-mobile" }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }