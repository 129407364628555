<template>
    <div>
        <div class="body-wrapper">
            <div class="columns is-gapless">
                <div class="column is-one-fifth is-hidden-mobile"></div>
                <div v-if="pass" class="column">
                    <h1>My {{ pass.passname }} Pass</h1>
                    <h2>{{ pass.festivalName }}</h2>
                    <p>{{ pass.passDescription }}</p>
                    <h3>Festival Shows</h3>
                    <PassSelector v-for="event in events" :key="event.id" :passes="[pass]" :event="event" :use="getUse(event.id)" />
                </div>
                <div class="column is-one-fifth is-hidden-mobile"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import PassSelector from "@/components/pass-selector";
//import store from "@/store";

export default {
  name: "passManager",
  props: ["id"],
  components: {
    PassSelector
  },
  computed: {
    ...mapGetters({
      passes: "passes",
      events: "events",
      festival: "festival",
    }),
    pass: function() {
        return this.passes.find(pass => pass.id == this.id);
    }
  },
  watch: {
    passes() {
        if (this.pass) {
            this.$store.dispatch("fetchFestivalEvents", this.pass.seasonId);
            console.log(this.pass.festivalId);
        } else console.log("wth");
    }
  },
  data() {
    return {}
  },
  async created() {
    console.log("passes", this.passes);
    this.$store.dispatch("fetchFestivalEvents", this.pass.seasonId);
    // this.$store.dispatch("fetchFestivalProfile", this.festival.festivalId);
    // if (this.festival.venue)
    //  await this.$store.dispatch("fetchVenue", this.festival.venue);
    // this.$store.dispatch("fetchFestivalEvents", this.pass.festivalId);
  },
  methods: {
    getUse(eventId) {
        if (!this.pass.uses) return null;
        return this.pass.uses.find(e => e.eventId == eventId);
    }
  }
}
</script>

<style>
.showblurb {
  max-height: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>