var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.event.passExempt
    ? _c(
        "div",
        { staticClass: "event" },
        [
          _c("div", { staticClass: "columns" }, [
            _c("div", { staticClass: "column" }, [
              _c("div", { staticClass: "eventimagerapper" }, [
                _c("div", { staticClass: "imageholder" }, [
                  _c("img", {
                    staticClass: "eventimage",
                    attrs: { src: _vm.event.image, alt: "" },
                  }),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "eventTitle" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "event", params: { id: _vm.event.id } },
                      },
                    },
                    [_vm._v(_vm._s(_vm.event.title))]
                  ),
                ],
                1
              ),
              _c("div", [_vm._v(_vm._s(_vm.event.act))]),
              _c("div", {
                staticClass: "showblurb",
                domProps: { innerHTML: _vm._s(_vm.event.description) },
              }),
            ]),
          ]),
          _vm.use
            ? _c("div", [
                _c("strong", [
                  _vm._v(
                    "You are seeing " +
                      _vm._s(_vm.event.title) +
                      " on " +
                      _vm._s(_vm.displayDate(_vm.use.date, _vm.use.time))
                  ),
                ]),
              ])
            : _vm._e(),
          !_vm.makingSelection && !_vm.use
            ? _c("DateSlider", {
                attrs: {
                  dates: _vm.event.dates,
                  visible: !_vm.makingSelection,
                },
                on: { setDate: _vm.setPassDate },
              })
            : _vm._e(),
          !_vm.use
            ? _c("div", { staticClass: "buttons is-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-primary",
                    class: { "is-loading": _vm.makingSelection },
                    attrs: { disabled: !_vm.dateSelected },
                    on: { click: _vm.makeSelection },
                  },
                  [_vm._v("Select Performance")]
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }