<template>
  <div class="body-wrapper">
    <div class="columns is-gapless">
      <div class="column is-one-fifth is-hidden-mobile"></div>
      <div class="column">
        <div class="container">
          <h1 v-if="!passes || passes.length == 0">Claim Your Pass!</h1>
          <h1 v-if="passes && passes.length > 0">My Passes</h1>
          <p>{{ statusMessage }}</p>
          <div v-for="pass in passes" :key="pass.id">
            <div  v-if="pass.seasonId != 'rpVhO7qvxr7dXYAhBWve'" class="pass">
              <div class="columns">
                <div class="column is-narrow"><a :href="pass.festivalImage"></a></div>
                <div class="column">
                  <div class="eventTitle">
                    <router-link
                      :to="{ name: 'festival', params: { festivalslug: 'tchfxiii' } }"
                      >{{ pass.festivalName }}</router-link
                    >
                  </div>
                  {{ pass.passname }}
                </div>
                <div class="column"><strong>Used:</strong> {{ pass.usedCount }}/{{ (pass.passType == "unlimited") ? "unlimited" : pass.limit }}</div>
                <div v-if="pass.passType == 'unlimited'"><button @click="$router.push({name: 'passManager', params: { id: pass.id }})" class="button is-primary">Use Pass</button></div>
                <div v-if="pass.passType == 'limited'"><button @click="$router.push({name: 'festival', params: { festivalslug: 'tchfiii' }})" class="button is-primary">Use Pass</button></div>
              </div>
              <table v-if="pass.seasonId != 'rpVhO7qvxr7dXYAhBWve'" class="table tixtable">
                <thead>
                  <tr>
                    <th>Date and time</th>
                    <th>Show</th>
                    <th>Tickets</th>
                  </tr>
                </thead>
                <tr v-for="(use, index) in pass.uses" :key="index">
                  <td>{{ use.date }} {{ use.time }}</td>
                  <td>{{ use.eventTitle }}</td>
                  <td>{{ use.quantity }} - {{ use.description }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-one-fifth is-hidden-mobile"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//import store from "@/store";

export default {
  name: "pass",
  props: ["id", "email"],
  computed: {
    ...mapGetters({
      requestStatus: "requestStatus",
      profile: "profile",
      passes: "passes",
    }),
    statusMessage: function () {
      if (this.requestStatus != "success" && this.requestStatus != "error")
        return "We're looking for your pass right now.";
      else if (this.requestStatus == "error")
        return "Invalid pass retrieval request. Please reach out to Gobo customer service if you believe you have a valid pass and are seeing this message.";
      else if (this.requestStatus == "success" && this.passes.length == 1)
        return "We have found your pass!";
      else if (this.requestStatus == "success" && this.passes.length > 1)
        return "We have found your passes!";
      else return "";
    },
  },
  methods: {},
  async created() {
    await this.$store.dispatch("checkSavedPass");
    if (!this.profile) await this.$store.dispatch("claimPass", { id: this.id, email: this.email });
  },
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";
.datelist .cal-body {
  border: none;
}
.event {
  clear: both;
  margin-bottom: 10px;
}
.container {
  padding-bottom: 30px;
}
.tixtable {
  margin: 50px 40px;
}
.pass {
  margin-bottom: 75px;
  border: 2px solid $gray4;
  border-radius: $radius;
  padding: 50px 0;
}
</style>
