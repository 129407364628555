var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", [
      _c("div", { staticClass: "columns is-gapless" }, [
        _c("div", { staticClass: "column is-one-fifth is-hidden-mobile" }),
        _vm.festival
          ? _c(
              "div",
              { staticClass: "column maincont" },
              [
                _c("div", { staticClass: "imagewrap" }, [
                  _c("img", {
                    staticClass: "event-image",
                    attrs: { src: _vm.festival.image },
                  }),
                ]),
                _c(
                  "div",
                  { staticClass: "calendar" },
                  [
                    _c("div", { staticClass: "cal-stripe" }),
                    _vm.festival && _vm.festival.start
                      ? _c("EventDateBadge", {
                          attrs: { date: _vm.festival.start.toDate() },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                false
                  ? _c("div", { staticClass: "tix-button" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button is-primary",
                          on: {
                            click: function ($event) {
                              return _vm.tickets()
                            },
                          },
                        },
                        [_vm._v(" Purchase Tickets ")]
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "description indent" },
                  [
                    _c(
                      "div",
                      [_c("EventDateline", { attrs: { event: _vm.festival } })],
                      1
                    ),
                    _c("h1", [_vm._v(_vm._s(_vm.festival.title))]),
                    _vm.venue
                      ? _c(
                          "div",
                          { staticClass: "venue-map" },
                          [
                            _c(
                              "gmap-map",
                              {
                                staticStyle: {
                                  width: "300px",
                                  height: "200px",
                                },
                                attrs: {
                                  center: _vm.venue.location,
                                  zoom: 17,
                                  mapTypeControl: "false",
                                  options: _vm.options,
                                },
                              },
                              [
                                _c("gmap-marker", {
                                  attrs: {
                                    position: _vm.venue.location,
                                    clickable: true,
                                    draggable: true,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.center = _vm.venue.location
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "map-legend" }, [
                              _c("h3", [_vm._v(_vm._s(_vm.venue.name))]),
                              _c("p", [_vm._v(_vm._s(_vm.venue.fullAddress))]),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.festivalProfile
                      ? _c("p", { staticClass: "act" }, [
                          _vm.festivalProfile && _vm.festivalProfile.image
                            ? _c("img", {
                                attrs: {
                                  src: _vm.festivalProfile.image,
                                  alt: "festivalProfile.name",
                                },
                              })
                            : _vm._e(),
                          !_vm.festivalProfile || !_vm.festivalProfile.urlslug
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.festivalProfile.name)),
                              ])
                            : _vm._e(),
                          _vm.festivalProfile && _vm.festivalProfile.urlslug
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    href:
                                      "/festival/" +
                                      _vm.festivalProfile.urlslug,
                                  },
                                },
                                [_vm._v(_vm._s(_vm.festivalProfile.name))]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.festival.description) },
                    }),
                    _c("h3", [_vm._v("Multi-Show Passes")]),
                    _vm._l(_vm.festival.passes, function (pass) {
                      return _c(
                        "div",
                        { key: pass.id, staticClass: "passclass" },
                        [
                          _c("h4", [_vm._v(_vm._s(pass.name))]),
                          _c("div", { staticClass: "columns" }, [
                            _c("div", { staticClass: "column" }, [
                              _vm._v(_vm._s(pass.description)),
                            ]),
                            !pass.status || pass.status != "soldout"
                              ? _c(
                                  "div",
                                  { staticClass: "column is-one-quarter" },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        "$" + _vm._s(pass.price) + " per pass"
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            pass.status && pass.status == "soldout"
                              ? _c(
                                  "div",
                                  { staticClass: "column is-one-quarter" },
                                  [
                                    _c("strong", { staticClass: "soldout" }, [
                                      _vm._v(
                                        "$" + _vm._s(pass.price) + " per pass"
                                      ),
                                    ]),
                                    _vm._v(" SOLD OUT "),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      )
                    }),
                    _c("div", { staticClass: "buttons is-right" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button is-primary is-pulled-right",
                          on: {
                            click: function ($event) {
                              return _vm.passes()
                            },
                          },
                        },
                        [_vm._v(" purchase passes ")]
                      ),
                    ]),
                  ],
                  2
                ),
                !_vm.festival.eventType ||
                _vm.festival.eventType != "videoOnDemand"
                  ? _c(
                      "div",
                      { staticClass: "performances indent" },
                      [
                        false
                          ? _c("FollowArtist", {
                              attrs: { artist: _vm.festivalProfile },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._m(0),
                _vm._l(_vm.events, function (event) {
                  return _c(
                    "div",
                    { key: event.id, staticClass: "event indent" },
                    [
                      _c("div", { staticClass: "columns" }, [
                        _c("div", { staticClass: "column" }, [
                          _c("div", { staticClass: "eventimagerapper" }, [
                            _c("div", { staticClass: "imageholder" }, [
                              _c("img", {
                                staticClass: "eventimage",
                                attrs: { src: event.image, alt: "" },
                              }),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "eventTitle" },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "event",
                                      params: { id: event.id },
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(event.title))]
                              ),
                            ],
                            1
                          ),
                          _c("div", [_vm._v(_vm._s(event.act))]),
                          _c("div", {
                            staticClass: "showblurb",
                            domProps: { innerHTML: _vm._s(event.description) },
                          }),
                        ]),
                      ]),
                    ]
                  )
                }),
              ],
              2
            )
          : _vm._e(),
        _c("div", { staticClass: "column is-one-fifth is-hidden-mobile" }),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "indent" }, [
      _c("h3", [_vm._v("Festival Shows")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }