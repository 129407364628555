var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "columns is-gapless" }, [
        _c("div", { staticClass: "column is-one-fifth is-hidden-mobile" }),
        _c("div", { staticClass: "column maincont" }, [
          _c("h1", [_vm._v("Help")]),
          _c("p", [
            _vm._v(
              "We're sorry you are having troubles purchasing tickets to your event. At Gobo, we are dedicated to providing a user-friendly and simple interface. If you are having any sort of problems or anything is confusing about our product, we want to hear from you right away. "
            ),
          ]),
          _c("p", [
            _vm._v("Email us at "),
            _c("a", { attrs: { href: "mailto:support@gobo.show" } }, [
              _vm._v("support@gobo.show"),
            ]),
          ]),
          _c("p", [
            _vm._v("You can also reach us via phone at (612) 225-5170."),
          ]),
        ]),
        _c("div", { staticClass: "column is-one-fifth is-hidden-mobile" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }